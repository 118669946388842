import { useEffect, useState } from 'react';
import './date_picker.css';
import { KeyboardDatePicker, DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns';
import itLocale from "date-fns/locale/it";

function CustomDatePicker ({register, apiErrors, errors, name, id, label, required, max, min, maxMessage='', minMessage=''}) {
  const [date, setDate] = useState(null)
  const [parsedDate, setParsedDate] = useState('')
  const [maxDate, setMaxDate] = useState()
  const [minDate, setMinDate] = useState()

  useEffect(()=>{
    let max_date = '';
    let min_date = '';

    if (min && min === '1-year') {
      min_date = new Date()

      min_date.setFullYear(min_date.getFullYear() - 1)

      min_date = min_date
        .toLocaleDateString("en-GB", {year:"numeric", month: '2-digit', day:"2-digit"})
        .split('/')
        .reverse()
        .join('-');

        
      setMinDate(min_date);
    }
    if (max === 'today') {
      max_date = new Date()
        .toLocaleDateString("en-GB", {year:"numeric", month: '2-digit', day:"2-digit"})
        .split('/')
        .reverse()
        .join('-');
        
      setMaxDate(max_date);
    }
    if (max === '18-years') {
      max_date = new Date()

      max_date.setFullYear(max_date.getFullYear() - 18)

      max_date = max_date
        .toLocaleDateString("en-GB", {year:"numeric", month: '2-digit', day:"2-digit"})
        .split('/')
        .reverse()
        .join('-');
        
      setMaxDate(max_date);
    }
  }, [max, min]);

  function handleDateChange (date) {
    setDate(date);

    if (date && ! Number.isNaN(date.getTime()) ) {
      setParsedDate(
        date.toLocaleDateString("en-GB", {year:"numeric", month: '2-digit', day:"2-digit"})
          .split('/')
          .reverse()
          .join('-')
      )
    } else {
      setParsedDate('')
    }
      
  }

  return (
    <div className="date_picker_main">
      <label htmlFor={name}>{label + (required ? '*' : '')}</label>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={itLocale}>
        <KeyboardDatePicker
          invalidDateMessage="Data non valida"
          openTo="year"
          animateYearScrolling
          inputVariant="outlined"
          format="dd/MM/yyyy"
          value={date}
          maxDateMessage={maxMessage}
          minDateMessage={minMessage}
          maxDate={maxDate || undefined}
          minDate={minDate || undefined}
          onChange={date => handleDateChange(date)}
          style={{width: '320px'}}
          cancelLabel='CANCELLA'
        />
      </MuiPickersUtilsProvider>
      <input type="hidden" value={parsedDate} name={name + (id ? id : '')} id={name + (id ? id : '')} ref={register({ required: required})}></input>

      {required && errors[name + (id ? id : '')] && <p className="error">Questo campo è obbligatorio</p>}
      {apiErrors && apiErrors[name] && apiErrors[name].map((error, ind)=>
        <p className="error" key={ind}>{error}</p>
      )}
    </div>
  );
}

export default CustomDatePicker;