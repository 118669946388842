import './footer.css';
import { NavLink } from 'react-router-dom';

function FooterAzimut() {
  return (
    <div className="footer">
      <small>D.M.O. PET CARE S.R.L. con socio unico Sede legale Pernumia (PD) Via Maseralino
        n. 23, C.F. e iscrizione Registro Imprese di Padova 04936500281 P.Iva 04936500281,
        REA PD-429913x, Capitale sociale € 5.000.000 i.v. Società soggetta all’attività
        di direzione e coordinamento di D.M.O. Dettaglio Moderno Organizzato S.p.a</small>
        <NavLink to="/cookie/">Cookie Policy</NavLink>
    </div>
  );
}

export default FooterAzimut;