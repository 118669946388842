import './thankyou_azimut.css';
import {Helmet} from "react-helmet";
import FooterAzimut from '../../components/footer/footer_azimut';

function ThankyouAzimut() {
  return (
    <>
    <Helmet>
      <title>Azimut | Isola dei tesori</title>
    </Helmet>
    <div className="thankyou_azimut" style={{backgroundImage: 'url(/assets/landing/azimut_logo.png), url(/assets/landing/azimut_header_background.png)'}}>
      <h1>Grazie!</h1>
      <h1>Controlla la tua email per confermare la registrazione.</h1>
    </div>
    <FooterAzimut/>
    </>
  );
}

export default ThankyouAzimut;