import './landing_azimut.css';
import { useForm, Controller } from 'react-hook-form';
import {Helmet} from "react-helmet";
import FooterAzimut from '../../components/footer/footer_azimut';
import LandingFormField from '../../components/landing_form_field/landing_form_field';
import CitySearchField from '../../components/city_search_field/city_search_field';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { scroller } from 'react-scroll';
import { NavLink } from 'react-router-dom';
import { postRegistration, optionRegistration} from '../../ApiCalls';
import CustomDatePicker from '../../components/date_picker/date_picker';

function LandingAzimut ({pageReceiver}) {
  const history = useHistory();
  const [ apiErrors, setApiErrors ] = useState({})
  const [ validationOptions, setValidationOptions ] = useState({})
  const [city, setCity] = useState(null);
  const [cityErrors, setCityErrors] = useState('');
  const { register, handleSubmit, errors, control } = useForm({
    card_number: '',
    first_name: '',
    last_name: '',
    sex: '',
    birth_date: '',
    address: '',
    house_number: '',
    cap: '',
    email: '',
    city_id: '',
    cellphone_number: '',
    telephone_number: '',
    fiscal_code: '',
    profession: '',
    privacy_registration: false,
    privacy_direct: false,
    privacy_profiling: false,
    privacy_marketing: false,
  });

  

  useEffect(()=>{
    optionRegistration('azimut/'+pageReceiver)
      .then((data)=>{
        setValidationOptions(data.actions.POST)
      })
  }, [pageReceiver]);

  function onSubmit (data) {
    let res = {};

    Object.keys(data).forEach(key=>{
      if (data[key] !== '') {
        if (
          key === 'privacy_direct' ||
          key === 'privacy_profiling' ||
          key === 'privacy_marketing'
        )
          res[key] = JSON.parse(data[key]);
        else
          res[key] = data[key];
      }
    })

    if (city) {
      res.city_id = city.id;
      sendData(res)
    } else {
      setCityErrors('Questo campo è obbligatorio')
    }
  };

  async function sendData (data) {
    let response = await postRegistration('azimut/'+pageReceiver, data);

    if (response && response.detail === 'E-mail di verifica inviata.')
      history.push('/azimut/thank-you')
    else {
      setApiErrors(response)
    }
  }

  function resetErrors () {
    setApiErrors({})
    setCityErrors('')
  }

  const services = [
    {
      icon: 'cart',
      name: 'Vasto assortimento di prodotti in negozio e online',
      desc: 'Assortimento ricco e completo, con reparti specializzati per un acquisto comodo e veloce dei prodotti delle migliori marche anche online.'
    },
    {
      icon: 'hands',
      name: 'Professionalità del personale',
      desc: 'Nei nostri reparti troverai assistenza qualificata all’acquisto e consulenze dedicate di esperti specializzati.'
    },
    {
      icon: 'scissors',
      name: 'Servizio Toelettatura',
      desc: 'Un toelettatore professionista è al servizio del tuo pet, per lavarlo e tosarlo con amore.'
    },
    {
      icon: 'water',
      name: 'Servizio Pet Laundry',
      desc: 'Ti aiutiamo a tenere in ordine e puliti gli accessori del tuo amico a quattro zampe, coperte, cucce, cappottini e tanto altro.'
    },
    {
      icon: 'fish',
      name: 'Reparto Acquariologia',
      desc: 'Un acquariofilo a tua disposizione per consigliarti i migliori prodotti e illustrarti le caratteristiche di tante specie da tutto il mondo.'
    },
    {
      icon: 'doctor',
      name: 'Consulenza Veterinaria',
      desc: 'Entra in contatto con i migliori professionisti per la salute del tuo pet. Consulenza veterinaria disponibile 24h su 24 e 7 giorni su 7.'
    },
    {
      icon: 'plus',
      name: 'Reparto Farmaci Veterinari',
      desc: 'La salute del tuo pet è sempre al primo posto, per questo stiamo introducendo reparti dedicati ai farmaci e parafarmaci veterinari.'
    },
    {
      icon: 'list',
      name: 'L’Assicurazione per Cane e Gatto',
      desc: 'La polizza assicurativa che protegge il tuo cane e il tuo gatto da tutti i rischi.'
    },
  ];

  return (
    <>
    <Helmet>
      <title>Azimut | Isola dei tesori</title>
    </Helmet>
    <div className="landing_azimut">

      <div className="landing_azimut_advantages" style={{backgroundImage: 'url(/assets/landing/azimut_logo.png), url(/assets/landing/azimut_header_background.png)'}}>
        <h1>BENVENUTO NEL CLUB DEI TESORI</h1>
        <h2>DA INVESTITORI A POTENZIALI CLIENTI</h2>
        <div className="advantages_container" >
          <img src="/assets/landing/card_gold.png" alt=""></img>
          <div className="advantages_content">
            <h3>I vantaggi esclusivi dedicati ai clienti Azimut</h3>
            <ul>
              <li>Sconto <b>Welcome del 25%</b> sul primo acquisto</li>
              <li>Sconto <b>10%</b> su tutti gli acquisti successivi</li>
              <li>Accesso ai <b>premi</b> del catalogo</li>
              <li>Privilegi nella partecipazione ai <b>concorsi</b></li>
              <li>Aggiornamento su novità ed iniziative in tempo reale</li>
            </ul>
          </div>
        </div>
        <h4>Investire in economia reale porta sempre grandi vantaggi</h4>
      </div>

      <div className="landing_azimut_header">
        <div className="landing_azimut_header_text">
          <h3>AZIMUT E L’ISOLA DEI TESORI  INSIEME PER CRESCERE</h3>
          <p>Non c’è nulla di più bello di diventare clienti della propria azienda per dare un contributo
            concreto alla sua crescita. Grazie al fondo Demos I, Azimut ha investito nella strategia di sviluppo
            industriale de L’Isola dei Tesori e ora ha deciso si sostenere il progetto con una partnership commerciale.</p>
          <p>Chi è cliente Azimut può diventare anche un cliente de L’Isola dei Tesori usufruendo di un mondo di
            vantaggi esclusi. Sottoscrivi subito questa offerta e contribuisci anche tu a far crescere il tuo investimento.</p>
          <span data-testid="register_scroll" onClick={()=>{scroller.scrollTo('azimut_register', {
            duration: 1000,
            smooth: 'easeOutQuad'
          })}} className="azimut_subscribe_button">Iscriviti</span>
        </div>
        
        <img className="dog_azimut" src="/assets/landing/dog_azimut.png" alt=""></img>
      </div>

      <div className="landing_azimut_services">
        <div className="services_title">
          <span></span>
          <h2>Perchè scegliere L'Isola dei Tesori</h2>
          <span></span>
        </div>
        <h5>Un mondo di servizi a misura dei tuoi piccoli grandi amici.</h5>
        <div className="services_list" style={{backgroundImage: 'url(/assets/landing/cat_azimut.png)'}}>
          {
            services.map((serv, ind)=>
              <div key={ind} className={ind%2===0 ? 'service' : 'service_inverted'}>
                <img src={`/assets/landing/services/${serv.icon}.svg`} alt=""></img>
                <div className="service_text">
                  <h4>{serv.name}</h4>
                  <p>{serv.desc}</p>
                </div>
              </div>
            )
          }
        </div>
      </div>

      <div id='azimut_register' className="landing_azimut_register">
        <span className="yellow_line"></span>
        <p>Compila il form ed entra subito nel mondo <b>L’Isola dei Tesori!</b></p>
        <form className="landing_form" onSubmit={handleSubmit(onSubmit)} id="owner_form">
          <div className="landing_form_grid">
            {pageReceiver === 'card-holder' &&
              <LandingFormField register={register} apiErrors={apiErrors} errors={errors} fieldProps={{
                name: 'card_number',
                label: 'Codice tessera azimut',
                required: true,
                type: 'text'
              }}/>
            }
            {validationOptions.first_name &&
              <LandingFormField register={register} apiErrors={apiErrors} errors={errors} fieldProps={{
                name: 'first_name',
                label: validationOptions.first_name.label,
                required: validationOptions.first_name.required,
                type: 'text'
              }}/>
            }
            {validationOptions.last_name &&
              <LandingFormField register={register} apiErrors={apiErrors} errors={errors} fieldProps={{
                name: 'last_name',
                label: validationOptions.last_name.label,
                required: validationOptions.last_name.required,
                type: 'text'
              }}/>
            }
            {validationOptions.email &&
              <LandingFormField register={register} apiErrors={apiErrors} errors={errors} fieldProps={{
                name: 'email',
                label: validationOptions.email.label,
                required: validationOptions.email.required,
                type: 'email'
              }}/>
            }
            {validationOptions.birth_date &&
              <CustomDatePicker
                register={register}
                apiErrors={apiErrors}
                errors={errors}
                name='birth_date'
                label={validationOptions.birth_date.label}
                max='18-years'
                maxMessage='Devi avere almeno 18 anni per poterti iscrivere'
                required={validationOptions.birth_date.required}
              />
            }
            {validationOptions.sex &&
              <LandingFormField register={register} apiErrors={apiErrors} errors={errors} fieldProps={{
                name: 'sex',
                label: validationOptions.sex.label,
                required: validationOptions.sex.required,
                type: 'select',
                default: ''
              }}>
                <option value="" disabled={true} hidden>Seleziona</option>
                {validationOptions.sex.choices.map((el, idx)=>(
                  <option key={idx} value={el.value}>{el.display_name}</option>
                ))}
              </LandingFormField>
            }
            {validationOptions.cellphone_number &&
              <LandingFormField register={register} apiErrors={apiErrors} errors={errors} fieldProps={{
                name: 'cellphone_number',
                label: validationOptions.cellphone_number.label,
                required: validationOptions.cellphone_number.required,
                type: 'tel'
              }}/>
            }
            {validationOptions.telephone_number &&
              <LandingFormField register={register} apiErrors={apiErrors} errors={errors} fieldProps={{
                name: 'telephone_number',
                label: validationOptions.telephone_number.label,
                required: validationOptions.telephone_number.required,
                type: 'tel'
              }}/>
            }
            {validationOptions.address &&
              <LandingFormField register={register} apiErrors={apiErrors} errors={errors} fieldProps={{
                name: 'address',
                label: validationOptions.address.label,
                required: validationOptions.address.required,
                type: 'text'
              }}/>
            }
            {validationOptions.house_number &&
              <LandingFormField register={register} apiErrors={apiErrors} errors={errors} fieldProps={{
                name: 'house_number',
                label: validationOptions.house_number.label,
                required: validationOptions.house_number.required,
                type: 'text'
              }}/>
            }
            {validationOptions.city_id &&
              <CitySearchField 
                name='search'
                label={validationOptions.city_id.label}
                required={validationOptions.city_id.required}
                setCity={setCity}
                errors={cityErrors}
              />
            }
            {validationOptions.cap &&
              <LandingFormField register={register} apiErrors={apiErrors} errors={errors} fieldProps={{
                name: 'cap',
                label: validationOptions.cap.label,
                required: validationOptions.cap.required,
                type: 'text'
              }}/>
            }
            {validationOptions.profession &&
              <LandingFormField register={register} apiErrors={apiErrors} errors={errors} fieldProps={{
                name: 'profession',
                label: validationOptions.profession.label,
                required: validationOptions.profession.required,
                type: 'select',
                default: ''
              }}>
                <option value="" disabled={true} hidden>Seleziona</option>
                {validationOptions.profession.choices.map((el, idx)=>(
                  <option key={idx} value={el.value}>{el.display_name}</option>
                ))}
              </LandingFormField>
            }
            {validationOptions.fiscal_code &&
              <LandingFormField register={register} apiErrors={apiErrors} errors={errors} fieldProps={{
                name: 'fiscal_code',
                label: validationOptions.fiscal_code.label,
                required: validationOptions.fiscal_code.required,
                type: 'text'
              }}/>
            }
          </div>
          
          <div className="checkbox_container">
            <p className="privacy_disclaimer">Letta l’<a href="/azimut/privacy/" target="blank">informativa sulla privacy</a> fornita dal Titolare:</p>
            <div className="landing_form_checkbox">
              <input type="checkbox" id="privacy_registration" name="privacy_registration" ref={register({ required: true })}></input>
              <label htmlFor="privacy_registration">
                {validationOptions.privacy_registration &&
                  validationOptions.privacy_registration.label}
                {validationOptions.privacy_registration &&
                  validationOptions.privacy_registration.required ? '*' : ''}
              </label>
            </div>
            {errors.privacy_registration && <p className="error" data-testid='privacy-error'>Questo campo è obbligatorio</p>}
          </div>
          <div className="checkbox_container marketing">
            <div className="landing_form_radio">
              <p>
                {validationOptions.privacy_direct &&
                  validationOptions.privacy_direct.label}
                {validationOptions.privacy_direct &&
                  validationOptions.privacy_direct.required ? '*' : ''}
              </p>
              <Controller
                render={() => {
                  return (
                    <>
                    <input type="radio" id="direct_allow" name="privacy_direct" ref={register({required: true})} value="true"></input>
                    <label htmlFor="direct_allow">Accetta</label>
                    <input type="radio" id="direct_disallow" name="privacy_direct" ref={register({required: true})} value="false"></input>
                    <label htmlFor="direct_disallow">Rifiuta</label>
                    </>
                  )      
                }}
                name="privacy_direct"
                control={control}
                defaultValue={false}
              />
              {errors.privacy_direct && <p className="error">Questo campo è obbligatorio</p>}
            </div>
            <div className="landing_form_radio">
              <p>
                {validationOptions.privacy_profiling &&
                  validationOptions.privacy_profiling.label}
                {validationOptions.privacy_profiling &&
                  validationOptions.privacy_profiling.required ? '*' : ''}
              </p>
              <Controller
                render={() => {
                  return (
                    <>
                    <input type="radio" id="profiled_allow" name="privacy_profiling" ref={register({required: true})} value="true"></input>
                    <label htmlFor="profiled_allow">Accetta</label>
                    <input type="radio" id="profiled_disallow" name="privacy_profiling" ref={register({required: true})} value="false"></input>
                    <label htmlFor="profiled_disallow">Rifiuta</label>
                    </>
                  )      
                }}
                name="privacy_profiling"
                control={control}
                defaultValue={false}
              />
              {errors.privacy_profiling && <p className="error">Questo campo è obbligatorio</p>}
            </div>
            <div className="landing_form_radio">
              <p>
                {validationOptions.privacy_marketing &&
                  validationOptions.privacy_marketing.label}
                {validationOptions.privacy_marketing &&
                  validationOptions.privacy_marketing.required ? '*' : ''}
              </p>

              <Controller
                render={() => {
                  return (
                    <>
                    <input type="radio" id="integrated_allow" name="privacy_marketing" ref={register({required: true})} value="true"></input>
                    <label htmlFor="integrated_allow">Accetta</label>
                    <input type="radio" id="integrated_disallow" name="privacy_marketing" ref={register({required: true})} value="false"></input>
                    <label htmlFor="integrated_disallow">Rifiuta</label>
                    </>
                  )      
                }}
                name="privacy_marketing"
                control={control}
                defaultValue={false}
              />

              {errors.privacy_marketing && <p data-testid='marketing-error' className="error">Questo campo è obbligatorio</p>}
            </div>
          </div>

          <input className="azimut_subscribe_button" type="submit" value="Iscriviti" onClick={resetErrors}/>
        </form>
      </div>

    </div>
    <FooterAzimut/>
    </>
  );
}

export default LandingAzimut;