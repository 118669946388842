import './header.css';

function Header() {
  return (
    <div className="header">
      <img className="header_logo" src="/assets/header/logo.png" alt="logo isola degli animali"></img>
      <div className="header_bottom"></div>
    </div>
  );
}

export default Header;