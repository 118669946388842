import './privacy_policy.css';
import Footer from '../../components/footer/footer';
import FooterAzimut from '../../components/footer/footer_azimut';

function PrivacyPolicy ({pageType}) {

  return (
    <div className={`privacy_policy ${pageType}`}>
      {pageType === 'isola' &&
      <>
        <div className="privacy_policy_content">

        <h1>Privacy Policy</h1>

        <p className="page_header">Gentile cliente, nel rispetto della normativa vigente in materia di protezione dei dati personali, Dmo pet care srl, con sede legale in Pernumia, via Maseralino n. 23 (Pd), c.f. e iscrizione registro imprese di Padova e p.iva  04936500281, e-mail privacy@isoladeitesori.it in qualità di titolare di trattamento La informa che ai sensi della normativa vigente applicabile (Regolamento Ue 2016/679 “GDPR”, D.Lgs. 196/2003 “Codice Privacy” come novellato dal D.Lgs. 101/2018, Provvedimenti specifici dell’Autorità per la Protezione dei dati personali) utilizzerà i dati identificativi e di contatto da Lei forniti per svolgere le attività di seguito elencate (finalità del trattamento) :</p>
        
        <div className="section">
          <p className="section_title">Fidelizzazione:</p>
          <p className="section_content">rilascio della carta fedeltà e gestione delle attività non esercitabili in forma anonima e necessarie per consentire ai sottoscrittori la fruizione e il riconoscimento di promozioni, l’offerta di prodotti, l’invio di premi, la partecipazione a raccolte punti e ad eventi promozionali riservati alla clientela fidelizzata. I dati verranno trattati per gli obblighi normativi, amministrativi e fiscali conseguenti al rilascio della carta fedeltà.</p>
        </div>
        
        <div className="section">
          <p className="section_title">Marketing diretto:</p>
          <p className="section_content">Svolgimento, previo suo espresso consenso scritto, di attività di marketing diretto, quali l’invio - anche tramite e-mail, sms ed mms, applicazioni di messaggistica multimediale istantanea e applicazioni mobile (cd. app) - di materiale pubblicitario, di comunicazioni aventi contenuto informativo e/o promozionale sui prodotti o servizi forniti dalla scrivente azienda, ivi compresi omaggi e campioni gratuiti; di compimento di ricerche di mercato volte, ad esempio, alla valutazione del grado di soddisfazione clientela e di comunicazione commerciale, oltre che alla valutazione del grado di soddisfacimento dei prodotti e dei servizi proposti.</p>
        </div>
        
        <div className="section">
          <p className="section_title">Marketing profilato:</p>
          <p className="section_content">Svolgimento, previo suo espresso consenso scritto, di attività di profilazione della clientela sulla  base  delle  scelte  di  acquisto  e  delle informazioni opzionalmente fornite in fase di registrazione al  programma di fidelizzazione o di successiva iscrizione al sito web. Potranno venire realizzate quindi con il suo consenso promozioni personalizzate basate sul suo profilo.</p>
        </div>
        
        <div className="section">
          <p className="section_title">Marketing integrato:</p>
          <p className="section_content">Comunicare, previo suo espresso consenso scritto, i suoi dati per loro specifiche finalità di marketing ad aziende facenti parte della compagine societaria di Dmo pet care srl appartenenti alle categorie merceologiche: grande distribuzione organizzata. Potranno venirle proposte quindi, con il suo consenso, offerte e promozioni su prodotti non direttamente venduti nei nostri negozi. </p>
        </div>
        
        <div className="section">
          <p className="section_title">Base giuridica del trattamento</p>
          <p className="section_content">Con riferimento alle finalità di cui al punto (a), la base giuridica del trattamento è l’esecuzione dei servizi forniti attraverso la fidelizzazione CARD DEI TESORI e da Lei richiesti (ai sensi dell’articolo 6, comma 1, lett. b del GDPR); invece, con riferimento alle finalità di cui ai punti (b), (c) e (d), la base giuridica del trattamento è il Suo eventuale consenso espresso liberamente (ai sensi dell’articolo 6, comma 1, lett. a del GDPR).</p>
        </div>
        
        <div className="section">
          <p className="section_title">Obbligatorietà del conferimento dei dati e facoltatività del consenso</p>
          <p className="section_content">I campi indicati come obbligatori (mediante asterisco) se non compilati comporteranno l’impossibilità di rilasciare la tessera fedeltà. I dati facoltativi, ove non forniti, non comporteranno conseguenze sul rilascio della tessera fedeltà e sulla partecipazione alle promozioni ad essa associate. La valutazione dei dati obbligatori è stata fatta tenendo conto dei principi di necessità e proporzionalità nel trattamento prescritti dalla normativa vigente. Per le finalità di cui alla lettera a), il conferimento dei dati è facoltativo, ma costituisce condizione necessaria ed indispensabile per il rilascio della tessera fedeltà: il mancato conferimento comporta per il richiedente l’impossibilità di ottenere la tessera. Per le finalità di cui alle lettere “b”, “c” e “d”, il conferimento dei dati è facoltativo e l’eventuale rifiuto di conferirli e di prestare il relativo consenso comporta l’impossibilità per la scrivente  azienda  di dar seguito alle attività di marketing diretto, integrato e di profilazione ivi indicate, ma non pregiudica la possibilità per il richiedente di ottenere il rilascio della tessera fedeltà e di accedere ai benefici connessi. In caso di mancato consenso, i clienti potranno usufruire per le finalità di fidelizzazione (accumulo punti, offerte riservate ai titolari di carta fedeltà) degli acquisti presso tutti i punti vendita della società emittente ma non potranno ricevere offerte promozionali. Il trattamento dei suoi dati sarà effettuato da personale di Dmo pet care srl, inquadrato ai sensi della privacy come autorizzato del trattamento ai sensi dell’art. 2 quater decies del Codice Privacy. </p>
        </div>
        
        <div className="section">
          <p className="section_title">Modalità del trattamento</p>
          <p className="section_content">Il trattamento dei dati per le finalità che precedono verrà effettuato in ossequio al Codice Privacy, al GDPR e a tutte le specifiche normative di settore ivi incluso quanto previsto dalle “regole del garante per i programmi di fidelizzazione” del 24 febbraio 2005 e dalle “linee guida in materia di trattamento di dati personali per profilazione on-line” del 19 marzo 2015. In ossequio alle “linee guida in materia di attività promozionale e contrasto allo spam” del 4 luglio 2013, evidenziamo che il consenso da lei eventualmente prestato per l’invio di comunicazioni commerciali, promozionali e di marketing tramite strumenti automatizzati si estenderà anche alle modalità tradizionali di contatto. i suoi dati sono trattati in forma sia elettronica che cartacea da personale autorizzato al trattamento nel rispetto della legge applicabile ed utilizzando adeguate misure di sicurezza nel rispetto della normativa in vigore anche ai sensi degli artt. 5 e 32 GDPR. In merito, si conferma, tra l’altro, l’adozione di opportune misure di sicurezza volte ad impedire accessi non autorizzati, furto, divulgazione, modifica o distruzione non autorizzata dei tuoi dati.</p>
        </div>
        
        <div className="section">
          <p className="section_title">Ambito di comunicazione e di diffusione</p>
          <p className="section_content">I dati potranno venire trattati dal personale della scrivente Dmo pet care srl per l’espletamento delle funzioni di ausilio e supporto alla clientela presso i punti vendita. i dati forniti non saranno in alcun caso oggetto di diffusione o comunicazione a terzi senza autorizzazione specifica, fatti salvi i soggetti cui la facoltà di accedere ai dati sia riconosciuta da disposizioni di legge, nonché i soggetti di cui ci avvaliamo per lo svolgimento di attività strumentali e/o accessorie alla gestione della tessera fedeltà o l’erogazione dei servizi e benefici ad essa connessi, ivi compresi i fornitori di soluzioni software, web application e servizi di storage erogati anche tramite sistemi di cloud computing (tutti i dati saranno custoditi su server situati nel territorio della comunità europea). I dati anagrafici e di contatto potranno in alcuni casi essere comunicati a soggetti terzi i quali, in qualità di autonomi titolari, li tratteranno per erogare i servizi acquistati o prescelti.</p>
          <p className="section_content">La informiamo che, tenendo conto del principio di minimizzazione nel trattamento dei dati sancito dall’art.5 del GDPR,  il Suo codice fiscale verrà comunicato ad Azimut Capital SGR S.p.A. affinché quest’ultima possa verificare la Sua qualità di Cliente Azimut avente diritto ai benefici oggetto della presente promozione.</p>
        </div>
        
        <div className="section">
          <p className="section_title">Periodo di conservazione dei dati</p>
          <p className="section_content">I dati relativi al dettaglio degli acquisti con riferimento a clienti individuabili verranno conservati per finalità di profilazione o di marketing per un periodo non superiore, rispettivamente, a dodici e      a ventiquattro mesi dalla loro registrazione; al superamento di tale limite temporale i dati dei dettagli acquisti saranno cancellati in modo automatico dai nostri sistemi informativi ovvero resi anonimi in modo permanente. I dati da lei forniti quali modalità di contatto per le finalità di  marketing  (facoltative),  potranno  venire  utilizzati  per  la  finalità    di  marketing  dalla  scrivente  società  fino   a   quando   non   esprimerà la volontà di uscire dal sistema “opt-in” mediante comunicazione attraverso le modalità indicate al paragrafo seguente. I dati trattati per gli obblighi normativi, amministrativi e fiscali conseguenti al rilascio della carta fedeltà verranno conservati per un periodo di 5 anni dalla loro creazione, fatte salve disposizioni normative specifiche (per esempio nel caso di partecipazione a concorsi o manifestazioni a premio riservate ai possessori di carta fedeltà, nei quali casi verrà fornita apposita informativa).</p>
        </div>
        
        <div className="section">
          <p className="section_title">Esercizio dei diritti ed eventuale reclamo all’autorità garante</p>
          <p className="section_content">Resta fermo il suo diritto a richiedere l’esercizio dei diritti previsti dall’art. 15 del regolamento ue 2016/679, oltre che i diritti previsti dagli artt. 17, 18 del regolamento ue 2016/679 riguardo alla cancellazione ed alla limitazione di accesso al trattamento, nelle modalità stabilite dall’art.12 del regolamento ue 2016/679. Il tutto senza formalità e senza sostenere costi come previsto dalla normativa vigente,  inviando un messaggio di posta elettronica all’indirizzo privacy-dpo@isoladeitesori.it  o attraverso comunicazione per iscritto al Responsabile per la protezione dei dati personali nominato dal Titolare, presso la sede aziendale. Un elenco dei responsabili interni ed esterni è disponibile presso il titolare di trattamento. Qualora la nostra società non le fornisca riscontro nei tempi previsti dalla normativa o la risposta all’esercizio dei suoi diritti non le risulti idonea, potrà proporre ricorso al garante per la protezione dei dati personali. In caso Lei ritenga che la nostra società abbia commesso una violazione del d.lgs. 196/2003 e/o del regolamento Ue 2016/679 potrà proporre ricorso al garante per la protezione dei dati personali. di seguito le coordinate: garante per la protezione dei dati personali, www.gpdp.it - www.garanteprivacy.it centralino telefonico: (+39) 06.69677.1</p>
        </div>
      </div>
        <Footer/>
      </>     
      }
      {pageType === 'azimut' &&
      <>
        <div className="privacy_policy_content">

          <h1>Privacy Policy</h1>

          <p className="page_header"></p>
          
          <div className="section">
            <p className="section_title">Fidelizzazione:</p>
            <p className="section_content">rilascio della carta fedeltà e gestione delle attività non esercitabili in forma anonima e necessarie per consentire ai sottoscrittori la fruizione e il riconoscimento di promozioni, l’offerta di prodotti, l’invio di premi, la partecipazione a raccolte punti e ad eventi promozionali riservati alla clientela fidelizzata. I dati verranno trattati per gli obblighi normativi, amministrativi e fiscali conseguenti al rilascio della carta fedeltà.</p>
          </div>

          <div className="section">
            <p className="section_title">Marketing diretto:</p>
            <p className="section_content">Svolgimento, previo suo espresso consenso scritto, di attività di marketing diretto, quali l’invio - anche tramite e-mail, sms ed mms, applicazioni di messaggistica multimediale istantanea e applicazioni mobile (cd. app) - di materiale pubblicitario, di comunicazioni aventi contenuto informativo e/o promozionale sui prodotti o servizi forniti dalla scrivente azienda, ivi compresi omaggi e campioni gratuiti; di compimento di ricerche di mercato volte, ad esempio, alla valutazione del grado di soddisfazione clientela e di comunicazione commerciale, oltre che alla valutazione del grado di soddisfacimento dei prodotti e dei servizi proposti.</p>
          </div>

          <div className="section">
            <p className="section_title">Marketing profilato:</p>
            <p className="section_content">Svolgimento, previo suo espresso consenso scritto, di attività di profilazione della clientela sulla base  delle  scelte  di  acquisto  e  delle informazioni opzionalmente fornite in fase di registrazione al  programma di fidelizzazione o di successiva iscrizione al sito web. Potranno venire realizzate quindi con il suo consenso promozioni personalizzate basate sul suo profilo.</p>
          </div>

          <div className="section">
            <p className="section_title">Marketing integrato:</p>
            <p className="section_content">Comunicare, previo suo espresso consenso scritto, i suoi dati per loro specifiche finalità di marketing ad aziende facenti parte della compagine societaria di Dmo pet care srl appartenenti alle categorie merceologiche: grande distribuzione organizzata. Potranno venirle proposte quindi, con il suo consenso, offerte e promozioni su prodotti non direttamente venduti nei nostri negozi. </p>
          </div>

          <div className="section">
            <p className="section_title">Base giuridica del trattamento</p>
            <p className="section_content">Con riferimento alle finalità di cui al punto (a), la base giuridica del trattamento è l’esecuzione dei servizi forniti attraverso la fidelizzazione CARD DEI TESORI e da Lei richiesti nonché l’adempimento di obblighi legali a quale è soggetto il titolare del trattamento (ai sensi degli articoli 6, comma 1, lett. b e c del GDPR); invece, con riferimento alle finalità di cui ai punti (b), (c) e (d), la base giuridica del trattamento è il Suo eventuale consenso espresso liberamente (ai sensi dell’articolo 6, comma 1, lett. a del GDPR).</p>
          </div>

          <div className="section">
            <p className="section_title">Obbligatorietà del conferimento dei dati e facoltatività del consenso</p>
            <p className="section_content">I campi indicati con asterisco se non compilati comporteranno l’impossibilità di rilasciare la tessera fedeltà. I dati facoltativi, ove non forniti, non comporteranno conseguenze sul rilascio della tessera fedeltà e sulla partecipazione alle promozioni ad essa associate. La valutazione dei dati obbligatori è stata fatta tenendo conto dei principi di necessità e proporzionalità nel trattamento prescritti dalla normativa vigente. Per le finalità di cui alla lettera a), il conferimento dei dati è necessario e costituisce condizione necessaria ed indispensabile per il rilascio della tessera fedeltà: il mancato conferimento comporta per il richiedente l’impossibilità di ottenere la tessera. Per le finalità di cui alle lettere “b”, “c” e “d”, il conferimento dei dati è facoltativo e l’eventuale rifiuto di conferirli e di prestare il relativo consenso comporta l’impossibilità per la scrivente azienda  di dar seguito alle attività di marketing diretto, integrato e di profilazione ivi indicate, ma non pregiudica la possibilità per il richiedente di ottenere il rilascio della tessera fedeltà e di accedere ai benefici connessi. In caso di mancato consenso, i clienti potranno usufruire per le finalità di fidelizzazione (accumulo punti, offerte riservate ai titolari di carta fedeltà) degli acquisti presso tutti i punti vendita della società emittente ma non potranno ricevere offerte promozionali. Il trattamento dei suoi dati sarà effettuato da personale di Dmo pet care srl, inquadrato ai sensi della privacy come autorizzato del trattamento ai sensi dell’art. 2 quater decies del Codice Privacy. </p>
          </div>

          <div className="section">
            <p className="section_title">Modalità del trattamento</p>
            <p className="section_content">Il trattamento dei dati per le finalità che precedono verrà effettuato in ossequio al Codice Privacy, al GDPR e a tutte le specifiche normative di settore ivi incluso quanto previsto dalle “regole del garante per i programmi di fidelizzazione” del 24 febbraio 2005 e dalle “linee guida in materia di trattamento di dati personali per profilazione on-line” del 19 marzo 2015. In ossequio alle “linee guida in materia di attività promozionale e contrasto allo spam” del 4 luglio 2013, evidenziamo che il consenso da lei eventualmente prestato per l’invio di comunicazioni commerciali, promozionali e di marketing tramite strumenti automatizzati si estenderà anche alle modalità tradizionali di contatto. i suoi dati sono trattati in forma sia elettronica che cartacea da personale autorizzato al trattamento nel rispetto della legge applicabile ed utilizzando adeguate misure di sicurezza nel rispetto della normativa in vigore anche ai sensi degli artt. 5 e 32 GDPR. In merito, si conferma, tra l’altro, l’adozione di opportune misure di sicurezza volte ad impedire accessi non autorizzati, furto, divulgazione, modifica o distruzione non autorizzata dei tuoi dati.</p>
          </div>

          <div className="section">
            <p className="section_title">Ambito di comunicazione e di diffusione</p>
            <p className="section_content">I dati potranno venire trattati dal personale della scrivente Dmo pet care srl per l’espletamento delle funzioni di ausilio e supporto alla clientela presso i punti vendita. i dati forniti non saranno in alcun caso oggetto di diffusione o comunicazione a terzi senza autorizzazione specifica, fatti salvi i soggetti cui la facoltà di accedere ai dati sia riconosciuta da disposizioni di legge, nonché i soggetti di cui ci avvaliamo per lo svolgimento di attività strumentali e/o accessorie alla gestione della tessera fedeltà o l’erogazione dei servizi e benefici ad essa connessi, ivi compresi i fornitori di soluzioni software, web application e servizi di storage erogati anche tramite sistemi di cloud computing (tutti i dati saranno custoditi su server situati nel territorio della comunità europea). I dati anagrafici e di contatto potranno in alcuni casi essere comunicati a soggetti terzi i quali, in qualità di autonomi titolari, li tratteranno per erogare i servizi acquistati o prescelti.</p>
            <p className="section_content">La informiamo che, tenendo conto del principio di minimizzazione nel trattamento dei dati sancito dall’art.5 del GDPR,  il Suo codice fiscale verrà comunicato ad Azimut Capital Management SGR S.p.A. affinché quest’ultima possa verificare la Sua qualità di Cliente Azimut avente diritto ai benefici oggetto della presente promozione.</p>
          </div>

          <div className="section">
            <p className="section_title">Periodo di conservazione dei dati</p>
            <p className="section_content">I dati relativi al dettaglio degli acquisti con riferimento a clienti individuabili verranno conservati per finalità di profilazione o di marketing per un periodo non superiore, rispettivamente, a dodici e      a ventiquattro mesi dalla loro registrazione; al superamento di tale limite temporale i dati dei dettagli acquisti saranno cancellati in modo automatico dai nostri sistemi informativi ovvero resi anonimi in modo permanente. I dati da lei forniti quali modalità di contatto per le finalità di  marketing  (facoltative),  potranno  venire  utilizzati  per  la  finalità    di  marketing  dalla  scrivente  società  fino   a   quando   non   esprimerà la volontà di uscire dal sistema “opt-in” mediante comunicazione attraverso le modalità indicate al paragrafo seguente. I dati trattati per gli obblighi normativi, amministrativi e fiscali conseguenti al rilascio della carta fedeltà verranno conservati per un periodo di 5 anni dalla loro creazione, fatte salve disposizioni normative specifiche (per esempio nel caso di partecipazione a concorsi o manifestazioni a premio riservate ai possessori di carta fedeltà, nei quali casi verrà fornita apposita informativa).</p>
          </div>

          <div className="section">
            <p className="section_title">Esercizio dei diritti ed eventuale reclamo all’autorità garante</p>
            <p className="section_content">Resta fermo il suo diritto a richiedere l’esercizio dei diritti previsti dall’art. 15 del regolamento ue 2016/679, oltre che i diritti previsti dagli artt. 17, 18 del regolamento ue 2016/679 riguardo alla cancellazione ed alla limitazione di accesso al trattamento, nelle modalità stabilite dall’art.12 del regolamento ue 2016/679. Il tutto senza formalità e senza sostenere costi come previsto dalla normativa vigente, inviando un messaggio di posta elettronica all’indirizzo privacy-dpo@isoladeitesori.it  o attraverso comunicazione per iscritto al Responsabile per la protezione dei dati personali nominato dal Titolare, presso la sede aziendale. Un elenco dei responsabili interni ed esterni è disponibile presso il titolare di trattamento. Qualora la nostra società non le fornisca riscontro nei tempi previsti dalla normativa o la risposta all’esercizio dei suoi diritti non le risulti idonea, potrà proporre ricorso al garante per la protezione dei dati personali. In caso Lei ritenga che la nostra società abbia commesso una violazione del d.lgs. 196/2003 e/o del regolamento Ue 2016/679 potrà proporre ricorso al garante per la protezione dei dati personali. di seguito le coordinate: garante per la protezione dei dati personali, www.gpdp.it - www.garanteprivacy.it centralino telefonico: (+39) 06.69677.1.</p>
          </div>
          
          
        </div>
        <FooterAzimut/>
      </>     
      }

    </div>
  );
}

export default PrivacyPolicy;