import { useEffect, useState } from 'react';
import './landing_form_field.css';

function LandingFormFields ({ register, errors, apiErrors, fieldProps, children }) {
  const [maxDate, setMaxDate] = useState('');
  const [minDate, setMinDate] = useState('');

  useEffect(()=>{
    if (fieldProps.type === 'date') {

      let max_date = '';
      let min_date = '';

      if (fieldProps.min && fieldProps.min === '1-year') {
        min_date = new Date()

        min_date.setFullYear(min_date.getFullYear() - 1)

        min_date = min_date
          .toLocaleDateString("en-GB", {year:"numeric", month: '2-digit', day:"2-digit"})
          .split('/')
          .reverse()
          .join('-');
      }
      if (fieldProps.max === 'today') {
        max_date = new Date()
          .toLocaleDateString("en-GB", {year:"numeric", month: '2-digit', day:"2-digit"})
          .split('/')
          .reverse()
          .join('-');
      }
      if (fieldProps.max === '18-years') {
        max_date = new Date()

        max_date.setFullYear(max_date.getFullYear() - 18)

        max_date = max_date
          .toLocaleDateString("en-GB", {year:"numeric", month: '2-digit', day:"2-digit"})
          .split('/')
          .reverse()
          .join('-');
      }

      setMaxDate(max_date);
      setMinDate(min_date);
    }
  }, [fieldProps.max, fieldProps.type]);

  return (
    <div className={`form_field ${fieldProps.type} ${fieldProps.class || ''}`}>
      <label htmlFor={fieldProps.name + (fieldProps.id ? fieldProps.id : '')}>{fieldProps.label}{fieldProps.required && '*'}</label>
      {fieldProps.type === 'select' ?
        <select  className="form_field_input"
          id={fieldProps.name + (fieldProps.id ? fieldProps.id : '')}
          name={fieldProps.name + (fieldProps.id ? fieldProps.id : '')}
          defaultValue={fieldProps.default}
          ref={register && register({ required: fieldProps.required})}
          onChange={(e)=>{if (fieldProps.changeCallback) {
            fieldProps.changeCallback(e.target.value, fieldProps.id);
          }}}
        >
          {children}
        </select>
      : fieldProps.type === 'textarea' ?
        <textarea className="form_field_input"
          id={fieldProps.name + (fieldProps.id ? fieldProps.id : '')}
          name={fieldProps.name + (fieldProps.id ? fieldProps.id : '')}
          ref={register({ required: fieldProps.required})}
        ></textarea>
      : fieldProps.type === 'datalist' ?
        <>
        <input className="form_field_input"
          id={fieldProps.name + (fieldProps.id ? fieldProps.id : '')}
          name={fieldProps.name + (fieldProps.id ? fieldProps.id : '')}
          list={fieldProps.name + '-list' + (fieldProps.id ? fieldProps.id : '')}
          ref={register({ required: fieldProps.required})}
          onChange={(e)=>{if (fieldProps.changeCallback) {
            fieldProps.changeCallback(e.target.value, fieldProps.id);
          }}}
        ></input>
        <datalist id={fieldProps.name + '-list' + (fieldProps.id ? fieldProps.id : '')}>
          {fieldProps.data.map((city, idx)=>(
            <option key={idx} value-id={city.id} value={city.text} ></option>
          ))}
        </datalist>
        </>
      :
        <input className="form_field_input"
          id={fieldProps.name + (fieldProps.id ? fieldProps.id : '')}
          name={fieldProps.name + (fieldProps.id ? fieldProps.id : '')}
          type={fieldProps.type}
          max={fieldProps.type === 'date' ? maxDate : ''}
          min={fieldProps.type === 'date' ? minDate : ''}
          ref={register({ required: fieldProps.required})}
          onChange={(e)=>{if (fieldProps.changeCallback) {
            fieldProps.changeCallback(e.target.value, fieldProps.id);
          }}}
        ></input>
        
      }
      {fieldProps.required && errors[fieldProps.name + (fieldProps.id ? fieldProps.id : '')] && <p className="error">Questo campo è obbligatorio</p>}
      {apiErrors && apiErrors[fieldProps.name] && apiErrors[fieldProps.name].map((error, ind)=>
        <p className="error" key={ind}>{error}</p>
      )}
    </div>
  );
}

export default LandingFormFields;