import './footer.css';
import { NavLink } from 'react-router-dom';

function Footer() {
  return (
    <div className="footer">
      <p>Club del Cucciolo è organizzato da <a href='https://www.isoladeitesori.it/' >L’Isola dei Tesori</a></p>
      <div className="footer_links">
        <a href='/assets/pdf/Regolamento_Club_del_Cucciolo.pdf' target="_blank" >Regolamento</a>
        <NavLink to="/club-del-cucciolo/privacy/">Privacy Policy</NavLink>
        <NavLink to="/cookie/">Cookie Policy</NavLink>
      </div>
    </div>
  );
}

export default Footer;