import './landing_components.css';

function ClubHeader() {
  return (
    <div className="landing_header_container"  style={{backgroundImage: 'url(/assets/cat_dog.svg)'}}>  
      <img className="landing_header_image" src="/assets/page_header.svg" alt=""></img>

      <img className="heart"  src="/assets/heart.png" alt=""></img>
      <img className="yellow_bg"  src="/assets/yellow_bg.svg" alt=""></img>
    </div>
  );
}

export default ClubHeader;