import './cookie_policy.css';
import Footer from '../../components/footer/footer';
import { useEffect } from 'react';

function CookiePolicy () {

  useEffect(()=>{

    let script = document.createElement('script');
    script.src = "https://consent.cookiebot.com/cb16cd5d-4a68-4e66-9440-0ed1be7dd0c2/cd.js";
    script.id = "CookieDeclaration";
    script.type = "text/javascript";
    script.async = true;
    document.getElementById('cookie-content').appendChild(script);

    return () => {
      if (document.getElementById('cookie-content')) {
        document.getElementById('cookie-content').removeChild(script)
      }
    }
    
  }, []);

  return (
    <div className='cookie_policy'>
      <div className="cookie_policy_content">

        <h1>Cookie Policy</h1>
        
        <div id="cookie-content"></div>
        
      </div>
      <Footer/>
    </div>
  );
}

export default CookiePolicy;