import { useState } from 'react';
import {getCities} from '../../ApiCalls';
import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';
import './city_search_field.css';

function CitySearchField ({name, label, required, setCity, errors}) {
  const [cities, setCities] = useState([])

  async function callGetCities (query) {

    let tmp_cities = await getCities(query);

    tmp_cities && tmp_cities.results &&
      setCities(tmp_cities.results);
  } 

  return (
    <div className="search_main">
      <label htmlFor={name}>{label + (required ? '*' : '')}</label>
      <Autocomplete
        noOptionsText='Nessuna città'
        id={name}
        onInputChange={(e, val)=>{
          callGetCities(val);
        }}
        onChange={(e, val)=>{
          setCity(val);
        }}
        options={cities}
        style={{
          fontSize: '14px',
          height: '38px',
          color: '#747474',
          backgroundColor: 'white',
          border: '1px solid #B1B1B1',
          borderRadius: '5px',
          width: '100%',
        }}
        getOptionLabel={(option) => option.text}
        renderInput={(params) => <TextField {...params} style={{paddingLeft: '13px'}} />}
      />
      {errors && 
        <p className="error" >{errors}</p>
      }
    </div>
  );
}

export default CitySearchField;