import './thankyou.css';
import PetCard from '../../components/pet_card/pet_card';
import Footer from '../../components/footer/footer';

function Thankyou({ pets, pageReceiver }) {
  return (
    <>
    <div className="thankyou" style={{backgroundImage: 'url(/assets/thankyou/dog_bg.svg), url(/assets/thankyou/cat_bg.svg)'}}>
      {pageReceiver === 'prospect' &&
        <p className="thankyou_intro">
          Controlla la tua email per confermare la registrazione e ottenere il tuo
          <b> buono sconto di benvenuto del 20% e la lista con tutti i prodotti necessari
          per accoglierlo al meglio.</b>
        </p>
      }
      {pageReceiver === 'card-holder' &&
        <p className="thankyou_intro">
          Controlla la tua email per confermare la registrazione e ottenere la tua
          <b> Fidelity Card, un buono sconto di benvenuto del 20% e la lista con tutti i prodotti necessari
          per accoglierlo al meglio.</b>
        </p>
      }
      {pets.length > 1 ?
        <h2>Ecco le schede dei tuoi cuccioli!</h2>
        :
        <h2>Ecco la scheda del tuo cucciolo!</h2>
      }
      <div className="pet_card_container">
        {
          pets.map((pet, ind)=>
            <PetCard key={ind} petData={{
              name: pet.name,
              type: pet.type,
              birth_date: pet.birth_date,
              size: pet.size,
              breed: pet.breed,
              sex: pet.sex,
              marks: pet.marks
            }}/>
          )
        }
      </div>
    </div>
    <Footer/>
    </>
  );
}

export default Thankyou;