import ClubHeader from '../../components/landing/club_header';
import Instructions from '../../components/landing/instructions';
import LandingForm from '../../components/landing/landing_form';
import Footer from '../../components/footer/footer';
import { useEffect } from 'react';
import { scroller } from 'react-scroll'

function LandingProspect ({setPets}) {

  useEffect(()=>{
    scroller.scrollTo('top', {
      duration: 0
    })
  }, [])

  return (
    <div className="landing_prospect">

      <ClubHeader/>

      <Instructions pageReceiver='prospect' />

      <LandingForm pageReceiver='prospect' setPets={setPets}/>

      <Footer/>

    </div>
  );
}

export default LandingProspect;