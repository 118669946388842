import './landing_components.css';

function Instructions({pageReceiver}) {

  return (
    <div className="landing_instructions" >

      <div className="instruction_row">
        
          {pageReceiver === 'prospect' &&
            <p>Per iscriverti al Club del Cucciolo è necessario sottoscrivere la <b>Card
            dei Tesori*</b>. Compila il form di seguito per ricevere la tua fidelity Card
            ed inserisci successivamente tutti i dati del tuo cucciolo per iscriverti
            al Club.<br/>
            <span>* questa Card ti consente di accedere anche a tutti i vantaggi del
            programma fedeltà de L’Isola dei Tesori solo in negozio.</span></p>
          }
          {pageReceiver === 'card-holder' &&
            <p><b>Compila il form</b> di seguito con i tuoi dati e quelli del tuo cucciolo,
            incluso il numero della tua Card dei Tesori e accederai a un mondo
            di vantaggi.</p>
          }
        <img src="/assets/landing/card.png" className="shift" alt=""></img>
      </div>
      <div className="instruction_row reverse">
        <p>Riceverai subito al tuo indirizzo email un <b>buono sconto di benvenuto del 20%</b> valido sulla prima spesa per il tuo cucciolo. Inoltre, una comoda lista <b>con tutti i prodotti necessari</b> per accoglierlo al meglio.</p>
        <img src="/assets/landing/discount.png" alt=""></img>
      </div>
      <div className="instruction_row">
        <p>In più, dopo aver utilizzato lo sconto di benvenuto, riceverai il <b>libretto guida</b> ed il <b>carnet dei vantaggi</b> da utilizzare quando vuoi nei primi 12 mesi di vita del cucciolo.</p>
        <img src="/assets/landing/guide_book.png" alt=""></img>
      </div>

    </div>
  );
}

export default Instructions;