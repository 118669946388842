import './App.css';
import {BrowserRouter, Switch, Route, Redirect} from 'react-router-dom';
import LandingChoose from './pages/landing_choose/landing_choose';
import LandingCardisti from './pages/landing_cardisti/landing_cardisti';
import LandingProspect from './pages/landing_prospect/landing_prospect';
import LandingAzimut from './pages/landing_azimut/landing_azimut';
import Thankyou from './pages/thankyou/thankyou';
import ConfirmEmail from './pages/thankyou/confirm-email';
import ThankyouAzimut from './pages/thankyou_azimut/thankyou_azimut';
import ConfirmEmailAzimut from './pages/thankyou_azimut/confirm-email_azimut';
import PrivacyPolicy from './pages/privacy_policy/privacy_policy';
import Header from "./components/header/header";
import { useState } from 'react';
import CookiePolicy from './pages/cookie_policy/cookie_policy';

function App() {
  const [pets, setPets] = useState([])

  return (
    <BrowserRouter>
      <div className="app" id="top">
        <Header/>
        <Switch>
          <Route exact path='/club-del-cucciolo/'>
            <LandingChoose/>
          </Route>

          <Route exact path='/club-del-cucciolo/card-holder/thank-you'>
            <Thankyou pets={pets}  pageReceiver={'card-holder'} />
          </Route>
          <Route exact path='/club-del-cucciolo/card-holder/confirm-email/:key'>
            <ConfirmEmail pageReceiver={'card-holder'}/>
          </Route>
          <Route exact path='/club-del-cucciolo/registrati/'>
            <LandingCardisti setPets={setPets} />
          </Route>

          <Route exact path='/club-del-cucciolo/prospect/thank-you'>
            <Thankyou pets={pets} pageReceiver={'prospect'} />
          </Route>
          <Route exact path='/club-del-cucciolo/prospect/confirm-email/:key'>
            <ConfirmEmail pageReceiver={'prospect'}/>
          </Route>
          <Route exact path='/club-del-cucciolo/ottieni-la-tua-card/'>
            <LandingProspect setPets={setPets} />
          </Route>

          <Route exact path='/azimut/thank-you'>
            <ThankyouAzimut />
          </Route>
          <Route exact path='/azimut/prospect/confirm-email/:key'>
            <ConfirmEmailAzimut pageReceiver='prospect' />
          </Route>
          <Route exact path='/azimut/card-holder/confirm-email/:key'>
            <ConfirmEmailAzimut pageReceiver='card-holder' />
          </Route>
          <Route exact path='/attiva-la-tua-card-azimut'>
            <LandingAzimut pageReceiver='card-holder' />
          </Route>
          <Route exact path='/ottieni-la-tua-card-azimut'>
            <LandingAzimut pageReceiver='prospect' />
          </Route>

          <Route exact path='/club-del-cucciolo/privacy'>
            <PrivacyPolicy pageType='isola' />
          </Route>
          <Route exact path='/azimut/privacy'>
            <PrivacyPolicy pageType='azimut' />
          </Route>

          <Route exact path='/cookie'>
            <CookiePolicy />
          </Route>

          <Redirect to="/club-del-cucciolo/" />
        </Switch>
      </div>
    </BrowserRouter>
  );
}

export default App;
