import './thankyou.css';
import Footer from '../../components/footer/footer';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {confirmEmail} from '../../ApiCalls';

function ConfirmEmail({pageReceiver}) {
  const {key} = useParams();
  const [validKey, setValidKey] = useState('initial');

  useEffect(()=>{
    (async ()=>{
      let response = await confirmEmail(key);
      setValidKey(response.detail === 'ok' ? 'ok' : 'failed');
    })()
  }, [key]);

  return (
    <>
    <div className="thankyou" style={{backgroundImage: 'url(/assets/background.png)'}}>
      <img className="thankyou_header_image" src="/assets/page_header.svg" alt=""></img>
      {validKey === 'ok' ?
        <>
        <h2>Benvenuto!</h2>
        <h2>Grazie per aver confermato la tua e-mail</h2>
        {pageReceiver === 'prospect' &&
          <p>Abbiamo  inviato alla tua casella di posta la tua Fidelity Card,
            il tuo buono  sconto di benvenuto del 20% e la lista con tutti i
            prodotti necessari  per il tuo cucciolo.
          </p>
        }
        {pageReceiver === 'card-holder' &&
          <p>Abbiamo inviato alla tua casella di posta il tuo buono 
            sconto di benvenuto del 20% e la lista con tutti i
            prodotti necessari per il tuo cucciolo.
          </p>
        }
        </>
      : validKey === 'failed' ?
        <>
        <h2>Ci dispiace</h2>
        <p>Questo codice non è valido</p>
        </>
      :
        <>
        <h2>Attendi un attimo</h2>
        <p>Stiamo inviando la tua mail</p>
        </>
      }
      
    </div>
    <Footer/>
    </>
  );
}

export default ConfirmEmail;