import './landing_choose.css';
import Footer from '../../components/footer/footer';
import { useEffect, useState } from 'react';
import { scroller } from 'react-scroll'
import {NavLink} from 'react-router-dom';

function LandingChoose () {
  const [windowWidth, setWindowWidth] = useState()

  useEffect(()=>{
    scroller.scrollTo('top', {
      duration: 0
    })

    setWindowWidth(window.innerWidth)

    window.addEventListener('resize', ()=>{
      setWindowWidth(window.innerWidth)
    })
  }, []);

  function expand (e) {
    let old_element = document.querySelector('.detail.active')

    if (old_element)
      old_element.classList.remove('active')

    if (old_element !== e.target.parentNode)
      e.target.parentNode.classList.toggle('active')
  }

  return (
    <div className="landing_choose">

      <div className="choose_header" style={{backgroundImage: 'url(/assets/heart.png)'}}>
        <img src="/assets/page_header.svg" alt=""></img>
        <p><b>Cucciolo in arrivo o già parte della famiglia?<br></br>
          Iscriviti gratuitamente al Club</b> e accedi a servizi e vantaggi esclusivi.
          In più, preziosi consigli su accoglienza, salute, nutrizione, igiene ed educazione
          per accompagnare il tuo cucciolo fino al compimento del suo 1° anno di vita.
        </p>
        <button onClick={()=>{scroller.scrollTo('advantages', {
            duration: 500,
            smooth: 'easeOutQuad'
          })}}>Scopri di più</button>
      </div>

      <img className="yellow_bg" src="/assets/yellow_bg.svg" alt=""></img>

      <div className="choose_main">
        <p className="title" id="subscribe">Iscriviti subito!</p>
        <p className="text"><b>Iscriversi è semplice e gratuito.</b>
          Ti basterà avere la <b>Card dei Tesori</b>: sottoscrivila subito se ancora non ce l’hai e scopri
          tutto ciò che ti aspetta nel Club.
        </p>
        <div className="choice_container">
          <div className="choice card">
            <p>Possiedo già la Card dei Tesori</p>
            <NavLink className="choose_link" to="/club-del-cucciolo/registrati/">Vai al servizio ▸</NavLink>
          </div>
          <div className="choice no_card">
            <p>Non possiedo la Card dei Tesori</p>
            <NavLink className="choose_link" to="/club-del-cucciolo/ottieni-la-tua-card/">Iscriviti ▸</NavLink>
          </div>
        </div>
        <p className="title" id="advantages">Un club esclusivo</p>
        <p className="text">Entrando nel Club del Cucciolo riceverai un <b>libretto guida con preziosi consigli</b> e
          il <b>carnet con tutti i vantaggi esclusivi</b> che trovi riassunti qui sotto.
        </p>

        {windowWidth > 1024 ? 
          <>
          <div className="book_images">
            <img className="book_1" src="/assets/choose/book_1.png" alt=""></img>
            <img className="book_2" src="/assets/choose/book_2.png" alt=""></img>
          </div>
          <div className="choose_details" style={{backgroundImage: 'url(/assets/choose/cloud.svg)'}}>

            <div className="detail">
              <p className="advantages_title" style={{color: '#52A51D'}}>Accoglienza</p>
              <p className="description">Procura tutto il necessario per farlo sentire a suo agio.</p>
              <ul>
                <li>Sconto di benvenuto per acquistare tutti i prodotti necessari.</li>
                <li>Incisione gratuita della sua medaglietta in negozio.</li>
              </ul>
            </div>
            <div className="detail">
              <p className="advantages_title" style={{color: '#40BBF0'}}>Salute</p>
              <p className="description">Rivolgiti al veterinario per gestire al meglio le sue specifiche esigenze sanitarie e preservare la sua salute.</p>
              <ul>
                <li>Sconti nei negozi a seguito di una vaccinazione e di una profilassi parassitaria eseguite nel suo primo anno di vita.</li>
                <li>Sconto sull’acquisto di un antiparassitario.</li>
                <li>Servizio VetH24 a condizioni vantaggiose da attivare in negozio.</li>
              </ul>
            </div>
            <div className="detail">
              <p className="advantages_title" style={{color: '#C24345'}}>Nutrizione</p>
              <p className="description">Per la sua alimentazione, affidati a prodotti specifici per cucciolo.</p>
              <ul>
                <li>Sconto su tutti i prodotti di alimentazione specifica in tutte le spese del suo primo anno di vita.</li>
              </ul>
            </div>
            <div className="detail">
              <p className="advantages_title" style={{color: '#E75F64'}}>Igiene e Bellezza</p>
              <p className="description">Mantieni pulito il tuo cucciolo e l’ambiente in cui vive, per garantire il suo benessere.</p>
              <ul>
                <li>Sconto su tutti i prodotti per la sua igiene.</li>
                <li>Condizioni vantaggiose sul primo servizio di toelettatura professionale che trovi nei nostri negozi.</li>
              </ul>
            </div>
            <div className="detail">
              <p className="advantages_title" style={{color: '#E86209'}}>Educazione e Gioco</p>
              <p className="description">Educa il cucciolo al nuovo ambiente, alle nuove abitudini e relazioni. Non dimenticare il tempo per il gioco.</p>
              <ul>
                <li>Sconto su tutti i giochi.</li>
                <li>Prima lezione gratuita in un centro di addestramento da attivare nei negozi convenzionati (solo per cani).</li>
              </ul>
            </div>

          </div>
          </>
        : 
          <div className="choose_details_mobile" style={{backgroundImage: 'url(/assets/choose/cloud.svg)'}}>

            <div className="detail">
              <p onClick={(e)=>expand(e)} className="advantages_title" style={{color: '#52A51D'}}>Accoglienza</p>
              <div className="extra">
                <p className="description">Procura tutto il necessario per farlo sentire a suo agio.</p>
                <ul>
                  <li>Sconto di benvenuto per acquistare tutti i prodotti necessari.</li>
                  <li>Incisione gratuita della sua medaglietta in negozio.</li>
                </ul>
              </div>

            </div>
            <div className="detail">
              <p onClick={(e)=>expand(e)} className="advantages_title" style={{color: '#40BBF0'}}>Salute</p>
              <div className="extra">
                <p className="description">Rivolgiti al veterinario per gestire al meglio le sue specifiche esigenze sanitarie e preservare la sua salute.</p>
                <ul>
                  <li>Sconti nei negozi a seguito di una vaccinazione e di una profilassi parassitaria eseguite nel suo primo anno di vita.</li>
                  <li>Sconto sull’acquisto di un antiparassitario.</li>
                  <li>Servizio VetH24 a condizioni vantaggiose da attivare in negozio.</li>
                </ul>
              </div>

            </div>
            <div className="detail">
              <p onClick={(e)=>expand(e)} className="advantages_title" style={{color: '#C24345'}}>Nutrizione</p>
              <div className="extra">
                <p className="description">Per la sua alimentazione, affidati a prodotti specifici per cucciolo.</p>
                <ul>
                  <li>Sconto su tutti i prodotti di alimentazione specifica in tutte le spese del suo primo anno di vita.</li>
                </ul>
              </div>

            </div>
            <div className="detail">
              <p onClick={(e)=>expand(e)} className="advantages_title" style={{color: '#E75F64'}}>Igiene e Bellezza</p>
              <div className="extra">
                <p className="description">Mantieni pulito il tuo cucciolo e l’ambiente in cui vive, per garantire il suo benessere.</p>
                <ul>
                  <li>Sconto su tutti i prodotti per la sua igiene.</li>
                  <li>Condizioni vantaggiose sul primo servizio di toelettatura professionale che trovi nei nostri negozi.</li>
                </ul>
              </div>
              
            </div>
            <div className="detail">
              <p onClick={(e)=>expand(e)} className="advantages_title" style={{color: '#E86209'}}>Educazione e Gioco</p>
              <div className="extra">
                <p className="description">Educa il cucciolo al nuovo ambiente, alle nuove abitudini e relazioni. Non dimenticare il tempo per il gioco.</p>
                <ul>
                  <li>Sconto su tutti i giochi.</li>
                  <li>Prima lezione gratuita in un centro di addestramento da attivare nei negozi convenzionati (solo per cani).</li>
                </ul>
              </div>

            </div>

          </div>
        }
      
        <button className="subscribe_button" onClick={()=>{scroller.scrollTo('subscribe', {
            duration: 500,
            smooth: 'easeOutQuad'
          })}}>Iscriviti subito</button>
      </div>
      
      <Footer/>

    </div>
  );
}

export default LandingChoose;