let apiUrl = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : '';

async function getCities (query='') {
  try {
    const res = await fetch(`${apiUrl}/api/autocomplete/comune/?q=${query}`, {
      method: 'GET',
    })

    return res.json();
  } catch (e) {
    console.error('error: ', e);
  }
}

async function postRegistration (url='', data={}) {
  try {
    const res = await fetch(`${apiUrl}/api/registration/${url}/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })

    return res.json();
  } catch (e) {
    console.error('error: ', e);
  }
}

async function optionRegistration (url='') {
  try {
    const res = await fetch(`${apiUrl}/api/registration/${url}/`, {
      method: 'OPTIONS'
    })

    return res.json();
  } catch (e) {
    console.error('error: ', e);
  }
}

async function confirmEmail (key) {
  const res = await fetch(`${apiUrl}/api/dj-rest-auth/account-confirm-email/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      key: key
    })
  });

  return res.json();
}

export { postRegistration, optionRegistration, confirmEmail, getCities };