import './landing_components.css';
import { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import LandingFormField from '../landing_form_field/landing_form_field';
import CitySearchField from '../city_search_field/city_search_field';
import { useHistory } from 'react-router-dom';
import {postRegistration, optionRegistration} from '../../ApiCalls';
import {NavLink} from 'react-router-dom';
import CustomDatePicker from '../date_picker/date_picker';

function LandingForm({pageReceiver, setPets}) {
  const history = useHistory();
  const [phase, setPhase] = useState(1);
  const [city, setCity] = useState(null);
  const [cityErrors, setCityErrors] = useState('');
  const [petNumber, setPetNumber] = useState(['1']);
  const [petType, setPetType] = useState([]);
  const [formData, setFormData] = useState({});
  const [apiErrors, setApiErrors] = useState({})
  const [validationOptions, setValidationOptions] = useState({})
  const { register, handleSubmit, errors, control } = useForm();
  const [marketingControl, setMarketingControl] = useState({
    direct: undefined,
    profiled: undefined,
    integrated: undefined,
  })

  useEffect(()=>{
    optionRegistration(pageReceiver)
      .then((data)=>{
        setValidationOptions(data.actions.POST)
      })
  }, [pageReceiver]);

  function handleMarketing (e) {
    let id = e.target.id;

    if (id === 'direct_disallow') {
      setMarketingControl(prev=>({...prev, direct: false}))
    }
    if (id === 'profiled_disallow') {
      setMarketingControl(prev=>({...prev, profiled: false}))
    }
    if (id === 'integrated_disallow') {
      setMarketingControl(prev=>({...prev, integrated: false}))
    }

    if (id === 'direct_allow') {
      setMarketingControl(prev=>({...prev, direct: true}))
    }
    if (id === 'profiled_allow') {
      setMarketingControl(prev=>({...prev, profiled: true}))
    }
    if (id === 'integrated_allow') {
      setMarketingControl(prev=>({...prev, integrated: true}))
    }
  }

  function onSubmit (data) {
    if (phase === 1) {
      let phaseData = {};
      Object.keys(data).forEach(key=>{
        if (data[key] !== '') {
          phaseData[key] = data[key];
        }
      })
      
      if (pageReceiver === 'prospect') {
        if (city) {
          phaseData.city_id = city.id;
          setPhase(2)
          setFormData(phaseData)
        } else {
          setCityErrors('Questo campo è obbligatorio')
        }
      } else {
        setPhase(2)
        setFormData(phaseData)
      }
    } else if (phase === 2) {
      let petArr = [];
      for (let i=0; i<petNumber.length; i++) {
        let pet = {};
        pet.name = data['name'+(i+1)];
        pet.birth_date = data['birth_date'+(i+1)];
        pet.type = data['type'+(i+1)];
        pet.breed = data['breed'+(i+1)];
        pet.sex = data['sex'+(i+1)];
        pet.marks = data['marks'+(i+1)];
        if (data['type'+(i+1)] === 'dog') {
          pet.size = data['size'+(i+1)];
        }
        petArr.push(pet);
      }
      setPets(petArr);

      let temp = {};
      temp.pets = petArr;

      temp.privacy_direct = JSON.parse(data.privacy_direct);
      temp.privacy_profiling = JSON.parse(data.privacy_profiling);
      temp.privacy_marketing = JSON.parse(data.privacy_marketing);
      temp.privacy_registration = data.privacy_registration;

      let res = Object.assign(temp, formData);
      sendData(res)
    }
  };

  async function sendData (data) {
    let response = await postRegistration(pageReceiver, data);
    if (response && response.detail === 'E-mail di verifica inviata.')
      history.push(`/club-del-cucciolo/${pageReceiver}/thank-you`);
    else {
      if (
        response.first_name ||
        response.last_name ||
        response.email ||
        response.birth_date ||
        response.sex ||
        response.address ||
        response.house_number ||
        response.cap ||
        response.city_id ||
        response.cellphone_number ||
        response.telephone_number ||
        response.profession ||
        response.fiscal_code ||
        response.card_number
      ) {
        setCity(null);
        setPhase(1);
      }
      setApiErrors(response)
    }
  }

  function resetErrors () {
    setApiErrors({});
    setCityErrors('');

    if (!city)  {
      setCityErrors('Questo campo è obbligatorio')
    }
  }



  return (
    <div className="landing_form_container">

      <span className="redline"></span>
      {pageReceiver === 'card-holder' &&
        <p className="form_container_text">Compila il form ed entra subito nel <b>Club del Cucciolo!</b></p>
      }
      {
        pageReceiver === 'prospect' &&
        <p className="form_container_text">Compila i campi per ricevere la tua <b>Card dei Tesori</b> e prosegui inserendo i dati del tuo cucciolo per iscriverti al <b>Club del Cucciolo</b>.</p>
      }
      <p className="fields_reminder">* I campi contrassegnati con l’asterisco sono obbligatori, inoltre puoi iscrivere il tuo cucciolo solo se non ha ancora compiuto 12 mesi.</p>
      <div className="phase_controller">
        <span className="active" data-testid='phase1_button' onClick={()=>setPhase(1)}>1</span>
        <div></div>
        <span className={phase === 2 ? 'active' : ''}>2</span>
      </div>
      {pageReceiver === 'card-holder' && phase === 1 &&
        <form className="landing_form" onSubmit={handleSubmit(onSubmit)} id="owner_form">
          <div className="landing_form_grid">
            <LandingFormField register={register} apiErrors={apiErrors} errors={errors} fieldProps={{
              name: 'first_name',
              label: 'Nome del Proprietario',
              required: true,
              type: 'text'
            }}/>
            <LandingFormField register={register} apiErrors={apiErrors} errors={errors} fieldProps={{
              name: 'last_name',
              label: 'Cognome del Proprietario',
              required: true,
              type: 'text'
            }}/>
            <LandingFormField register={register} apiErrors={apiErrors} errors={errors} fieldProps={{
              name: 'email',
              label: 'Email',
              required: true,
              type: 'email'
            }}/>
            <LandingFormField register={register} apiErrors={apiErrors} errors={errors} fieldProps={{
              name: 'card_number',
              label: 'Numero Card dei Tesori',
              required: true,
              type: 'text'
            }}/>
            <LandingFormField register={register} apiErrors={apiErrors} errors={errors} fieldProps={{
              name: 'cellphone_number',
              label: 'Telefono cellulare',
              required: false,
              type: 'tel'
            }}/>
            <LandingFormField register={register} apiErrors={apiErrors} errors={errors} fieldProps={{
              name: 'telephone_number',
              label: 'Telefono fisso',
              required: false,
              type: 'tel'
            }}/>
          </div>
          <input className="landing_form_submit" type="submit" value="Avanti" onClick={resetErrors}/>
        </form>
      }

      {pageReceiver === 'prospect' && phase === 1 &&
        <form className="landing_form" onSubmit={handleSubmit(onSubmit)} id="owner_form">
          <div className="landing_form_grid">
            <LandingFormField register={register} apiErrors={apiErrors} errors={errors} fieldProps={{
              name: 'first_name',
              label: 'Nome',
              required: true,
              type: 'text'
            }}/>
            <LandingFormField register={register} apiErrors={apiErrors} errors={errors} fieldProps={{
              name: 'last_name',
              label: 'Cognome',
              required: true,
              type: 'text'
            }}/>
            <LandingFormField register={register} apiErrors={apiErrors} errors={errors} fieldProps={{
              name: 'sex',
              label: 'Sesso',
              required: false,
              type: 'select',
              default: ''
            }}>
              <option value="" disabled={true} hidden>Seleziona</option>
              <option value="female">Femmina</option>
              <option value="male">Maschio</option>
            </LandingFormField>

            <CustomDatePicker
              register={register}
              apiErrors={apiErrors}
              errors={errors}
              name='birth_date'
              label='Data di nascita'
              max='18-years'
              maxMessage='Devi avere almeno 18 anni per poterti iscrivere'
              required={true}
            />

            <LandingFormField register={register} apiErrors={apiErrors} errors={errors} fieldProps={{
              name: 'address',
              label: 'Indirizzo',
              required: true,
              type: 'text'
            }}/>
            <LandingFormField register={register} apiErrors={apiErrors} errors={errors} fieldProps={{
              name: 'house_number',
              label: 'Numero civico',
              required: true,
              type: 'text'
            }}/>
            <LandingFormField register={register} apiErrors={apiErrors} errors={errors} fieldProps={{
              name: 'cap',
              label: 'CAP',
              required: true,
              type: 'text'
            }}/>
            
            <CitySearchField 
              name='search'
              label='Città'
              required={true}
              setCity={setCity}
              errors={cityErrors}
            />

            <LandingFormField register={register} apiErrors={apiErrors} errors={errors} fieldProps={{
              name: 'profession',
              label: 'Professione',
              required: false,
              type: 'select',
              default: ''
            }}>
              <option value="" disabled={true} hidden>Seleziona</option>
              {validationOptions.profession &&
                validationOptions.profession.choices.map((prof, idx)=>(
                  <option key={idx} value={prof.value}>{prof.display_name}</option>
                ))
              }
            </LandingFormField>
            <LandingFormField register={register} apiErrors={apiErrors} errors={errors} fieldProps={{
              name: 'fiscal_code',
              label: 'Codice fiscale',
              required: false,
              type: 'text'
            }}/>
            <LandingFormField register={register} apiErrors={apiErrors} errors={errors} fieldProps={{
              name: 'cellphone_number',
              label: 'Telefono cellulare',
              required: false,
              type: 'tel'
            }}/>
            <LandingFormField register={register} apiErrors={apiErrors} errors={errors} fieldProps={{
              name: 'telephone_number',
              label: 'Telefono fisso',
              required: false,
              type: 'tel'
            }}/>
            <LandingFormField register={register} apiErrors={apiErrors} errors={errors} fieldProps={{
              name: 'email',
              label: 'Email',
              required: true,
              type: 'email',
              class: 'wide',
            }}/>
          </div>
          <input className="landing_form_submit" type="submit" value="Avanti" onClick={resetErrors}/>
        </form>
      }

      {phase === 2 && 
        <form className="landing_form" onSubmit={handleSubmit(onSubmit)} id="pet_form">
          <LandingFormField fieldProps={{
            name: 'pets_num',
            label: 'N. Cuccioli',
            required: false,
            type: 'select',
            default: petNumber.join(),
            changeCallback: (val)=>{
              setPetNumber(val.split(','))
            }
          }}>
            <option value="1">1</option>
            <option value="1,2">2</option>
            <option value="1,2,3">3</option>
            <option value="1,2,3,4">4</option>
          </LandingFormField>
          {
            petNumber.map((num, ind)=>(
              <div key={ind} className="landing_form_pets">
                <p className="pet_number">CUCCIOLO N. {num}</p>
                <div className="landing_form_grid">
                <LandingFormField register={register} apiErrors={apiErrors} errors={errors} fieldProps={{
                  id: num,
                  name: 'name',
                  label: 'Nome del cucciolo',
                  required: true,
                  type: 'text'
                }}/>
                <LandingFormField register={register} apiErrors={apiErrors} errors={errors} fieldProps={{
                  id: num,
                  name: 'type',
                  label: 'Tipologia animale',
                  required: true,
                  changeCallback: (val, id)=>{
                    setPetType(pets => {
                      pets[id-1] = val;
                      return [...pets];
                    })
                  },
                  type: 'select',
                  default: ''
                }}>
                  <option value="" disabled={true} hidden>Seleziona</option>
                  <option value="dog">Cane</option>
                  <option value="cat">Gatto</option>
                </LandingFormField>
                {petType[num-1] === 'dog' &&
                  <LandingFormField register={register} errors={errors} fieldProps={{
                    id: num,
                    name: 'size',
                    label: 'Taglia',
                    required: false,
                    type: 'select',
                    default: ''
                  }}>
                    <option value="" disabled={true} hidden>Seleziona</option>
                    <option value="small">Piccolo</option>
                    <option value="medium">Medio</option>
                    <option value="large">Grande</option>
                  </LandingFormField>
                }
                <CustomDatePicker
                  register={register}
                  apiErrors={apiErrors}
                  errors={errors}
                  id={num}
                  name='birth_date'
                  label='Data di nascita'
                  max='today'
                  min='1-year'
                  maxMessage="Data non valida"
                  minMessage="Il cucciolo deve avere al massimo 12 mesi di vita."
                  required={true}
                />
                <LandingFormField register={register} errors={errors} fieldProps={{
                  id: num,
                  name: 'sex',
                  label: 'Sesso',
                  required: false,
                  type: 'select',
                  default: ''
                }}>
                  <option value="" disabled={true} hidden>Seleziona</option>
                  <option value="female">Femmina</option>
                  <option value="male">Maschio</option>
                </LandingFormField>
                <LandingFormField register={register} errors={errors} fieldProps={{
                  id: num,
                  name: 'breed',
                  label: 'Razza',
                  required: false,
                  type: 'text'
                }}/>
                <LandingFormField register={register} errors={errors} fieldProps={{
                  id: num,
                  name: 'marks',
                  label: 'Segni particolari / carattere (es. vivace, pigrone…)',
                  required: false,
                  type: 'textarea'
                }}/>
                </div>
              </div>
            ))
          }

          <div className="checkbox_container">
            <p className="privacy_disclaimer">Letta l’<a href="/club-del-cucciolo/privacy/" target="_blank" >informativa sulla privacy</a> fornita dal Titolare:</p>
            <div className="landing_form_checkbox">
              <input type="checkbox" id="privacy_registration" name="privacy_registration" ref={register({ required: true })}></input>
              <label htmlFor="privacy_registration">
                {validationOptions.privacy_registration &&
                  validationOptions.privacy_registration.label}
                {validationOptions.privacy_registration &&
                  validationOptions.privacy_registration.required ? '*' : ''}
              </label>
            </div>
            {errors.privacy_registration && <p className="error" data-testid='privacy-error'>Questo campo è obbligatorio</p>}
          </div>
          <div className="checkbox_container marketing">
            <div className="landing_form_radio">
              <p>Acconsento a ricevere comunicazioni circa eventi, offerte e promozioni (marketing diretto)</p>
              <Controller
                render={() => {
                  return (
                    <>
                    <input type="radio" id="direct_allow" name="privacy_direct" ref={register({required: true})} value="true" onChange={(e)=>{handleMarketing(e)}}></input>
                    <label htmlFor="direct_allow">Accetta</label>
                    <input type="radio" id="direct_disallow" name="privacy_direct" ref={register({required: true})} value="false" onChange={(e)=>{handleMarketing(e)}}></input>
                    <label htmlFor="direct_disallow">Rifiuta</label>
                    </>
                  )      
                }}
                name="privacy_direct"
                control={control}
              />
              {errors.privacy_direct && <p className="error">Questo campo è obbligatorio</p>}
            </div>
            <div className="landing_form_radio">
              <p>Acconsento all’analisi del mio profilo e abitudini di acquisto al fine di ricevere offerte e promozioni personalizzate (profilazione).</p>
              <Controller
                render={() => {
                  return (
                    <>
                    <input type="radio" id="profiled_allow" name="privacy_profiling" ref={register({required: true})} value="true" onChange={(e)=>{handleMarketing(e)}}></input>
                    <label htmlFor="profiled_allow">Accetta</label>
                    <input type="radio" id="profiled_disallow" name="privacy_profiling" ref={register({required: true})} value="false" onChange={(e)=>{handleMarketing(e)}}></input>
                    <label htmlFor="profiled_disallow">Rifiuta</label>
                    </>
                  )      
                }}
                name="privacy_profiling"
                control={control}
              />
              {errors.privacy_profiling && <p className="error">Questo campo è obbligatorio</p>}
            </div>
            <div className="landing_form_radio">
              <p>La comunicazione dei suoi dati alle altre aziende del
                gruppo per ricevere offerte e promozioni personalizzate (Marketing integrato)</p>

              <Controller
                render={() => {
                  return (
                    <>
                    <input type="radio" id="integrated_allow" name="privacy_marketing" ref={register({required: true})} value="true" onChange={(e)=>{handleMarketing(e)}}></input>
                    <label htmlFor="integrated_allow">Accetta</label>
                    <input type="radio" id="integrated_disallow" name="privacy_marketing" ref={register({required: true})} value="false" onChange={(e)=>{handleMarketing(e)}}></input>
                    <label htmlFor="integrated_disallow">Rifiuta</label>
                    </>
                  )      
                }}
                name="privacy_marketing"
                control={control}
              />

              {errors.privacy_marketing && <p className="error">Questo campo è obbligatorio</p>}
            </div>
            {(marketingControl.direct === false || marketingControl.profiled === false || marketingControl.integrated === false) &&
              <p className="error marketing_control">Se non acconsenti al trattamento dei dati, non potrai ricevere tutti i benefici del Club del Cucciolo</p>
            }
          </div>
          
          <div className="landing_form_submit_container">
            <p onClick={()=>setPhase(1)}>{'< Indietro'}</p>
            <input className="landing_form_submit_2" type="submit" value="Conferma" />
          </div>
        </form>
      }

      {pageReceiver === 'prospect' && 
        <div className="disclaimer">
          <div className="content">
            <img src="/assets/landing/dog.svg" alt=""></img>
            <p>Possiedo già la Card dei Tesori</p>
            <img src="/assets/landing/cat.svg" alt=""></img>
          </div>
          
          <NavLink to="/club-del-cucciolo/registrati/">Vai al servizio</NavLink>
        </div>
      }
      {pageReceiver === 'card-holder' &&
        <div className="disclaimer">
        <div className="content">
          <img src="/assets/landing/dog.svg" alt=""></img>
          <p>Non possiedo la Card dei Tesori</p>
          <img src="/assets/landing/cat.svg" alt=""></img>
        </div>
        
        <NavLink to="/club-del-cucciolo/ottieni-la-tua-card">Vai al servizio</NavLink>
      </div>
      }

    </div>
  );
}

export default LandingForm;