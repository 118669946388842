import { useEffect, useState } from 'react';
import './pet_card.css';

function PetCard({ petData }) {
  const { name, type, birth_date, size, breed, sex, marks } = petData;
  const [typeData, setTypeData] = useState({});

  useEffect(()=>{
    switch (type) {
      case 'dog':
        setTypeData({
          img: 'cane.svg',
          icon: 'dog_size.svg',
          type: 'Cane',
          size: size && (size === 'small' ? 'Piccola' : size === 'medium' ? 'Media' : 'Grande'),
        })
        break;
      case 'cat':
        setTypeData({
          img: 'gatto.svg',
          icon: 'cat_size.svg',
          type: 'Gatto',
        })
        break;
      default:
        break;
    }
    setTypeData((prev)=>
      Object.assign(prev, {
        birth_date: new Date(birth_date).toLocaleDateString("it-IT", {year:"numeric", month: '2-digit', day:"2-digit"}),
        sex: sex === 'male' ? 'Maschio' : 'Femmina',
      })
    )
  }, [birth_date, sex, size, type]);
  
  return Object.keys(typeData).length > 0 ? (
    <div className="pet_card">
      <img className="pet_pic" src={`/assets/thankyou/${typeData.img}`} alt="" data-testid='pet_img'></img>
      <div className="pet_data">
        <h1 className="pet_name">{name}</h1>
        <div className="pet_info">
          <img className="large" src={`/assets/thankyou/${typeData.icon}`} alt=""></img>
          <p>Tipologia: {typeData.type}</p>
        </div>
        {type==='dog' && size &&
          <div className="pet_info">
            <img className="large" src="/assets/thankyou/dog_size.svg" alt=""></img>
            <p>Taglia: {typeData.size}</p>
          </div>
        }
        <div className="pet_info">
          <img src="/assets/thankyou/calendar.svg" alt=""></img>
          <p>Data di nascita: {typeData.birth_date}</p>
        </div>
        {sex &&
          <div className="pet_info">
            <img src="/assets/thankyou/pencil.svg" alt=""></img>
            <p>Sesso: {typeData.sex}</p>
          </div>
        }
        {breed &&
          <div className="pet_info">
            <img src="/assets/thankyou/pencil.svg" alt=""></img>
            <p>Razza: {breed}</p>
          </div>
        }
        {marks &&
          <div className="pet_info">
            <img src="/assets/thankyou/pencil.svg" alt=""></img>
            <p>Segni particolari: {marks}</p>
          </div>
        }


        
      </div>
    </div>
  ): null;
}

export default PetCard;