import './thankyou_azimut.css';
import {Helmet} from "react-helmet";
import FooterAzimut from '../../components/footer/footer_azimut';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {confirmEmail} from '../../ApiCalls';

function ConfirmEmailAzimut({pageReceiver}) {
  const {key} = useParams();
  const [validKey, setValidKey] = useState('initial');

  useEffect(()=>{
    (async ()=>{
      let response = await confirmEmail(key);
      setValidKey(response.detail === 'ok' ? 'ok' : 'failed');
    })()
  }, [key]);

  return (
    <>
    <Helmet>
      <title>Azimut | Isola dei tesori</title>
    </Helmet>
    <div className="thankyou_azimut"  style={{backgroundImage: 'url(/assets/landing/azimut_logo.png), url(/assets/landing/azimut_header_background.png)'}}>
      {validKey === 'ok' ?
        <>
        <h1>La tua email è stata confermata, grazie!</h1>
        {pageReceiver === 'prospect' &&
          <p>A breve riceverai sulla tua casella di posta la nostra <b>Card dei Tesori</b> e lo <b>sconto di benvenuto del 25%.</b></p>
        }
        <img src="/assets/landing/card_gold.png" alt=""></img>
        </>
      : validKey === 'failed' ?
        <>
        <h1>Ci dispiace</h1>
        <p>Questo codice non è valido</p>
        </>
      :
        <>
        <h1>Attendi un attimo</h1>
        <p>Stiamo inviando la tua mail</p>
        </>
      }
    </div>
    <FooterAzimut/>
    </>
  );
}

export default ConfirmEmailAzimut;